<template>
    <div id="detailPage">
      <div class="top-nav-bar">
        <van-nav-bar @click-left="$router.back()">
          <van-icon class="back-arrow" name="arrow-left"  slot="left" />
          <div class="right" slot="right" @click.stop>
            <i class="icon icon-star" @click="collectVideos" :class="{active: isStar === 1}"></i>
            <i class="icon icon-all" @click="detailShowFun(detail)"></i>
          </div>
        </van-nav-bar>
      </div>
      <div class="container" :style="{'padding-bottom':isPaddingBottom ? '0' : '1.41333rem'}"  ref="container">
        <div @click="getAuthority" tag="div" class="banner">
          <van-image class="img" :src="detail.coverAcrossTemp" fit="cover"></van-image>
          <div class="mask" >
            <div class="item item-2">
              <div></div>
              <div class="right">
                {{ detail.time }}
              </div>
            </div>
            <i class="icon icon-play"></i>
          </div>
        </div>
        <div class="section section-1">
          <div class="section-content">
            <div class="title">{{detail.name}}</div>
            <div class="desrc">
              <div class="left">
                <div class="item vip" v-if="detail.type === 2">HD</div>
                <div class="item larget" v-if="detail.type === 3"><van-image class="img" :src="require('@/assets/images/icon_tag_06.png')"></van-image></div>
                <div class="item" v-if="detail.categoryName">{{ detail.categoryName }}</div>
              </div>
              <div class="right">
                <i class="icon icon-eyes"></i>
                <div class="name">{{ detail.sumNumber }}</div>
              </div>
            </div>
            <div class="price-box" v-if="detail.whetherBuy === 0">
              <span class="status" v-if="detail.type !== 1 && detail.putaway !== 2">{{ detail.realCost }}円</span>
              <span class="status" v-else>無料</span>
              <span class="del" v-if="detail.putaway!==2">{{ detail.originalCost }}円</span>
              <span class="del" v-else></span>
            </div>
          </div>
        </div>
        <div class="section section-2">
          <div class="section-title">動画紹介</div>
          <div class="section-content">
             {{ detail.briefIntroduction }}
          </div>
        </div>
        <div class="section section-4" v-if="detail.whetherBuy === 0">
          <div class="coupon">
            <ul class="couponUl">
              <li @click="$router.push({name:'CouponsIndex'})">
                <div class="left">
                  <span>クーポンを受け取る</span>
                </div>
                <div class="right">
                  <van-image class="img" fit="cover" :src="require('@/assets/images/img_text_04.png')"></van-image>
                  <van-image class="img" fit="cover" :src="require('@/assets/images/icon_arrowRight_07.png')"></van-image>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- 买了视频且是普通用户 -->
        <div class="section section-5" v-if="detail.whetherBuy === 1 && getLevel===1">
          <div class="section-content" @click="$router.push({name:'MemberIndex'})">
            <van-image class="img" :src="require('@/assets/images/bg_03.png')"></van-image>
            <div class="left">
              <div class="left-title">会員になる</div>
              <div class="left-text">有料動画を見放題！</div>
            </div>
            <div class="right">
              <van-image class="right-img" :src="require('@/assets/images/bg_07.png')"></van-image>
            </div>
          </div>
        </div>
        <div class="section section-3"  v-if="detail.list && detail.list.length!==0">
          <div class="section-title">おススメ作品</div>
          <div class="section-content">
            <van-row class="list" type="flex">
              <van-col
                v-for="item in detail.list"
                :key="item.id"
                :span="section3.span"
              >
                <router-link
                  :to="{name: 'Detail', query: {id: item.id}}"
                  class="list-item"
                  replace
                >
                  <ListItem1 :detail="item" @showFun="detailShowFun(item)">
                    <template v-slot:views><span></span></template>
                    <template v-slot:content><span></span></template>
                  </ListItem1>
                </router-link>
              </van-col>
            </van-row>
          </div>
        </div>
      </div>
      <!-- 底部按钮开始 -->
      <div class="fixBtn"  v-if="detail.whetherBuy === 0 && detail.putaway !== 2">
        <!-- 如果不是免费 -->
        <template v-if="detail.type !== 1">
          <div>
            <!-- 如果是vip视频 且是普通用户 -->
            <template v-if="detail.type===2 && getLevel===1">
              <van-button class="btnBig" @click="$router.push({name:'MemberIndex'})">スタンダード会員になる<div class="small"></div></van-button>
              <van-button class="btnSmall"  @click="toBuy(detail.id)">購入</van-button>
            </template>
            <!-- 如果是4k视频 且是普通用户 或者是 vip用户 -->
            <template v-if="detail.type===3 && (getLevel!==4 && (getLevel===1|| getLevel===3)) ">
              <van-button class="btnBig" @click="$router.push({name:'MemberIndex'})">プレミアム会員になる<div class="small">もっとお得</div></van-button>
              <van-button class="btnSmall"  @click="toBuy(detail.id)">購入</van-button>
            </template>
          </div>
        </template>
        <!-- 如果是免费的 -->
        <template v-else>
          <!-- 如果不是最高会员级别则显示 -->
          <van-button v-if="getLevel===1" class="btn" @click="$router.push({name:'MemberIndex'})">プレミアム会員になる<br />（すべての作品を見放題）</van-button>
        </template>
      </div>
      <!-- 底部按钮结束 -->

      <!-- 分享与稍后播放弹层开始 -->
      <PopupShow
        :detail="actions"
        @later="later"
        :showFun="showFun"
        @upDate="upDate"
      >
      </PopupShow>
      <!-- 分享与稍后播放弹层结束 -->

      <!-- 会员弹窗开始 -->
      <van-popup
        class="popup popup-showMember"
        v-model="showMember"
        closeable
        close-icon="close"
      >
        <div class="content">
          <div class="title">ご案内</div>
          <!-- 如果是vip视频 且是普通用户 -->
          <template  v-if="detail.type===2 && getLevel===1">
            <div class="info">限定動画です。プレミアム会員になりませんか？</div>
            <van-button class="btn btn01" @click="$router.push({name:'MemberIndex'})">スタンダード会員になる</van-button>
            <van-button van-button class="btn btn02" @click="toBuy(detail.id)">購入</van-button>
          </template>
          <!-- 如果是4k视频 且是普通用户 或者是 vip用户 -->
          <template v-if="detail.type===3 && (getLevel!==4 && (getLevel===1|| getLevel===3)) ">
            <div class="info">この動画は４K動画、プレミアム会員になりませんか</div>
            <van-button class="btn btn01" @click="$router.push({name:'MemberIndex'})">プレミアム会員になる</van-button>
            <van-button van-button class="btn btn02" @click="toBuy(detail.id)">購入</van-button>
          </template>
        </div>
      </van-popup>
      <!-- 会员弹窗结束 -->

    </div>
</template>

<script>
import { NavBar } from 'vant';
import { ListItem1, PopupShow } from '@/components';
import { mapGetters } from 'vuex';
export default {
  name: 'detail',
  components: {
    'van-nav-bar': NavBar,
    ListItem1,
    PopupShow
  },
  data () {
    return {
      id: '',
      detail: {},
      isStar: 2, // 1：表示收藏  2：表示未收藏
      section3: {
        span: 12
      },

      member: false,
      showFun: false, // 稍后观看与分享的弹层
      showMember: false, // 会员弹窗
      showPlay: true, // 分辨率弹窗
      flag: false, // false 表示当前的视频详情页  true 表示底部列表
      actions: {} // 用来存储分享与添加或移除稍后观看的弹层
    };
  },
  created () {
  },
  mounted () {
    // 获取视频数据信息
    this.init();
    this.onResize();

  },
  computed: {
    ...mapGetters(['htmlWidth']),
    ...mapGetters(['personInfo']),

    /* 获取会员等级 */
    getLevel () {

      /**
        @param level
                  1.普通
                  3.标准
                  4.高级
      */
      let level = 1;
      let { personInfo: { jurisdiction } } = this;
      if (jurisdiction && jurisdiction.length) {
        for (let item of jurisdiction) {
          if (item === 3) {
            level = 3;
          } else if (item === 4) {
            level = 4;
          }
        }
      }
      // console.log(level + 'level');
      // console.log(this.detail.type + 'detail.type');
      // console.log(this.detail.whetherBuy + 'detail.whetherBuy');
      return level;
    },

    /* 判断底部是否需要padding-bottom */
    isPaddingBottom () {
      const { personInfo, detail, getLevel } = this;
      return personInfo.jurisdiction && personInfo.jurisdiction.includes(detail.type + 1) || detail.whetherBuy !== 0 || ((getLevel === 3 || getLevel === 4) && detail.type === 1);

      // 会员是否有该视频权限 personInfo.jurisdiction.includes(detail.type + 1)
      // 会员是否有购买 detail.whetherBuy !== 0
      // 会员不是普通会员且是免费视频 ((getLevel === 3 || getLevel === 4) && detail.type === 1
    }

  },
  watch: {
    '$route.query': function () {
      this.init();
    },
    htmlWidth () {
      this.onResize();
    }
  },
  methods: {
    init () {
      const { query: { id } } = this.$route;
      this.id = id * 1;
      this.getVideoDetail();
      this.$refs.container.scrollTop = 0;
    },

    /* 获取视频详情页的接口数据 */
    getVideoDetail () {
      let { id, $utils } = this;
      if (id) {
        this.$http.get('video/videoDetail', {
          params: {
            videoId: id
          }
        }).then((res) => {
          const data = res.data || {};
          this.detail = data;
          this.isStar = data.whetherCollect;
        });
      } else {
        $utils.failToast('データの取得に失敗しました');
      }
    },

    /* 判断会员权限进行弹层 */
    getAuthority (e) {
      const { id, detail: { whetherBuy, type, putaway }, personInfo: { jurisdiction }, $utils, $router } = this;
      console.log(putaway);

      if (putaway === 1) {

        /* 判断是否有token */
        if ($utils.getToken()) {

          /* 如果没买 且是(vip 或者 4k 视频) 且不是对应会员 */
          if (whetherBuy === 0 && type !== 1 && !jurisdiction.includes(type + 1)) {
            this.showMember = true;
            return false;
          }

          $router.push({
            name: 'Video',
            query: { id }
          });
        } else {
          // 用户未登录
          /*
            免费可以观看
            其他跳转至登录页
          */
          if (type === 1) {
            $router.push({
              name: 'Video',
              query: { id }
            });
          } else {
            $utils.toast({
              message: 'ログインへ',
              onClose: () => {
                $router.push({
                  name: 'Login',
                  params: {
                    type: 1
                  }
                });
              }
            });
          }
        }
      } else {
        $router.push({
          name: 'Video',
          query: { id }
        });
      }

    },

    /* 收藏视频 */
    collectVideos () {
      const { $route, $utils, $router } = this;
      if ($utils.getToken()) {
        this.$http.get('video/collectVideo', {
          params: {
            videoId: $route.query.id
          },
          showLoading: true
        }).then((res) => {
          this.isStar = res.data.collect;
          res.data.collect === 1 ? $utils.successToast('お気に入りに追加しました。') : $utils.successToast('お気に入り外す');
        });
      } else {
        $utils.toast({
          message: 'ログインへ',
          onClose: () => {
            $router.push({
              name: 'Login',
              type: 1
            });
          }
        });
      }

    },

    /* 添加或移除稍后观看视频 */
    later () {
      this.getVideoDetail();
    },

    /* 分享视频 */
    share () {
      this.getVideoDetail();
    },

    /* 详情分类分享 */
    detailShowFun (item) {
      const { $utils, $router } = this;
      if ($utils.getToken()) {
        this.showFun = !this.showFun;
        const { id, whetherLater, putaway, type, whetherBuy, name } = item;
        this.actions = { id, whetherLater, putaway, type, whetherBuy, name };
      } else {
        $utils.toast({
          message: 'ログインへ',
          onClose: () => {
            $router.push({
              name: 'Login',
              params: {
                type: 1
              }
            });
          }
        });
      }

    },


    upDate (flag) {
      this.showFun = flag;
    },

    /* 购买视频 */
    toBuy (id) {
      this.$router.push({
        name: 'VideoBuy',
        query: {
          videoId: id,
          payMent: this.detail.realCost
        }
      });
    },

    /* 页面伸缩时进行响应式 */
    onResize () {
      const { htmlWidth, section3 } = this;
      section3.span = 12;
      if (htmlWidth > 1200) {
        section3.span = 6;
      } else if (htmlWidth > 768) {
        section3.span = 8;
      }
    }
  }
};
</script>

<style scoped lang="less">
#detailPage {
  display: flex;
  flex-direction: column;
  height: 100%;

  /deep/
  .top-nav-bar {
    .van-nav-bar {
      .right {
        display: flex;
        align-items: center;

        .icon {
          width: 48px;
          height: 48px;

          &:not(:first-child) {
            margin-left: 16px;
          }

          &.icon-star {
            background-image: url("~@/assets/images/icon_star_02.png");

            &.active {
              background-image: url("~@/assets/images/icon_star_01.png");
            }
          }

          &.icon-all {
            background-image: url("~@/assets/images/icon_all.png");
          }
        }
      }
    }
  }

  .container {
    height: 100%;
    padding-bottom: 106px;
    overflow-x: hidden;
    overflow-y: auto;

    .banner {
      position: relative;
      height: 520px;

      .img {
        width: calc(100% + 40px);
        height: 100%;
        margin-right: -20px;
        margin-left: -20px;
      }

      .mask {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);

        .item {
          position: absolute;
          left: 0;
          justify-content: space-between;
          width: 100%;

          &,
          .left,
          .right {
            display: flex;
            align-items: center;
          }

          &.item-2 {
            bottom: 0;
            padding: 14px 16px;
            font-size: 22px;
            line-height: 30px;
          }
        }

        .icon-play {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 80px;
          height: 80px;
          background-image: url("~@/assets/images/icon_arrowRight_03_.png");
          transform: translate(-50%, -50%);
        }
      }
    }

    .section {
      padding: 28px;
      margin-bottom: 26px;
      background-color: @primaryBg;

      .section-title {
        font-size: 28px;
        line-height: 38px;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      &.section-1 {
        .section-content {
          & > :nth-child(n):not(:last-child) {
            margin-bottom: 22px;
          }

          .title {
            font-size: 28px;
            line-height: 38px;
          }

          .desrc {
            display: flex;
            justify-content: space-between;

            .left {
              display: flex;
              flex-wrap: wrap;

              .item {
                height: 36px;
                padding: 4px 28px;
                margin-right: 20px;
                margin-bottom: 20px;
                font-size: 20px;
                line-height: 28px;
                background-color: rgba(255, 255, 255, 0.1);
                border-radius: 6px;
                .text-overflow();

                &.vip {
                  font-size: 20px;
                  font-weight: 400;
                  line-height: 24px;
                  color: #0a0a0a;
                  background: linear-gradient(230deg, #ffdc4b 0%, #ffa143 100%);
                  border-radius: 6px;
                }

                &.larget {
                  padding: 0;

                  .img {
                    width: 80px;
                    height: 36px;
                  }
                }
              }
            }

            .right {
              display: flex;
              align-items: center;
              font-size: 22px;
              line-height: 30px;

              .icon-eyes {
                width: 24px;
                height: 24px;
                margin-right: 8px;
                background-image: url("~@/assets/images/icon_eyesOpen_02.png");
              }
            }
          }

          .price-box {
            .status {
              margin-right: 24px;
              font-size: 36px;
              color: #ed544f;
            }
          }
        }
      }

      &.section-2 {
        .section-content {
          font-size: 24px;
          line-height: 32px;
          opacity: 0.8;
        }
      }

      &.section-3 {
        .section-content {
          .list {
            margin-right: -11px;
            margin-left: -11px;

            /deep/
            .list-item {
              width: 100%;
              padding-right: 11px;

              /* width: 336px; */
              padding-left: 11px;
              margin-bottom: 24px;

              .thumb {
                height: 236px;
              }
            }
          }
        }
      }

      &.section-4 {
        .coupon {
          .couponUl {
            height: auto;

            li {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 80px;
              margin-bottom: 20px;
              line-height: 80px;
              // background:linear-gradient(107deg, #FF814A 0%, #FF605A 100%);
              background: linear-gradient(107deg, #ff814a 0%, #ff605a 100%);
              border-radius: 16px;
              opacity: 1;

              .left {
                height: 100%;
                padding-left: 86px;
                font-size: 26px;
                background-image: url("~@/assets/images/icon_coupon_01.png");
                background-repeat: no-repeat;
                background-position: 12px 20px;
              }

              .right {
                position: relative;
                background-repeat: no-repeat;
                background-position: right bottom;
                background-size: contain;

                .img {
                  display: inline-block;
                  vertical-align: middle;

                  &:first-child {
                    width: 80px;
                    height: 28px;
                  }

                  &:last-child {
                    width: 32px;
                    height: 32px;
                    margin-right: 24px;
                  }
                }
              }
            }
          }

          .noData {
            font-size: 20px;
            line-height: 28px;
            color: #fff;
            opacity: 0.7;
          }
        }
      }

      &.section-5 {
        // background-image: url('~@/assets/images/bg_03.png');
        .section-content {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 144px;

          .img {
            position: absolute;
            width: 100%;
            height: 100%;
          }

          .left {
            position: relative;
            z-index: 1;
            padding-left: 32px;
            font-size: 36px;
            line-height: 48px;
            color: #fff;
            text-shadow: 0 2px 3px rgba(198, 31, 23, 0.3);
            opacity: 1;

            .left-text {
              font-size: 26px;
              opacity: 0.8;
            }
          }

          .right {
            width: 312px;
            height: 100%;

            .right-img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .fixBtn {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    box-sizing: border-box;
    padding: 28px;
    background: #1b1c1d;

    .btn {
      width: 100%;
      font-size: 30px;
      color: #4d2323;
      background: linear-gradient(180deg, #ffd64a 0%, #d2a408 100%);
      border: none;
      border-radius: 16px;
    }

    div {
      .btnBig {
        width: 67%;
        height: 96px;
        margin-right: 3%;
        color: #000;
        background: linear-gradient(180deg, #ffd64a 0%, #d2a408 100%);
        border: none;
        border-radius: 16px;
        opacity: 1;

        .small {
          font-size: 22px;
          line-height: 30px;
          color: #4d2323;
          opacity: 0.6;
        }
      }

      .btnSmall {
        width: 30%;
        height: 96px;
        background: linear-gradient(113deg, #36abf5 0%, #7acbff 100%);
        border: none;
        border-radius: 16px;
        opacity: 1;
      }
    }
  }

  .popup-share {
    padding: 44px 38px 28px;

    .content {
      .title {
        text-align: center;
      }

      .share {
        display: flex;
        justify-content: space-between;
        margin-top: 46px;

        .share-icon {
          .img {
            box-sizing: border-box;
            display: inline-block;
            width: 96px;
            height: 96px;
            border: 1px dashed #fff;
          }

          div {
            width: 96px;
            font-size: 24px;
            line-height: 32px;
            color: #fff;
            text-align: center;
            opacity: 0.8;
            .text-overflow();
          }
        }
      }

      .btn {
        margin-top: 68px;

        /deep/ button {
          width: 100%;
          font-size: 26px;
          line-height: 34px;
          color: #fff;
          background: #393a3b;
          border: none;
          border-radius: 16px;
          opacity: 0.8;
        }
      }
    }
  }

  .popup-allFun {
    padding: 44px 38px 28px;

    .content {
      .allFun {
        .fun {
          display: flex;
          align-items: center;
          height: 112px;
          font-size: 26px;
          line-height: 34px;
          line-height: 112px;
          color: #fff;
          border-bottom: 0.5px solid rgba(255, 255, 255, 0.05);
          opacity: 1;

          .icon {
            width: 48px;
            height: 48px;

            &.icon-addLater {
              background-image: url("~@/assets/images/icon_addLater_01.png");
            }

            &.icon-delLater {
              background-image: url("~@/assets/images/icon_delLater_01.png");
            }

            &.icon-share {
              background-image: url("~@/assets/images/icon_share_05.png");
            }
          }

          span {
            margin-left: 24px;
          }
        }
      }

      .btn {
        margin-top: 68px;

        /deep/ button {
          width: 100%;
          font-size: 26px;
          line-height: 34px;
          color: #fff;
          background: #393a3b;
          border: none;
          border-radius: 16px;
          opacity: 0.8;
        }
      }
    }
  }

  .popup-showMember {
    width: 590px;
    height: 526px;
    background: #2d2d2f;
    border-radius: 24px;
    opacity: 1;

    .content {
      padding: 0 38px;
      margin-top: 46px;
      font-size: 26px;
      line-height: 20px;
      color: #fff;
      text-align: center;

      .title {
        margin-bottom: 42px;
        font-size: 30px;
        line-height: 36px;
        color: #fff;
        opacity: 1;
      }

      .info {
        margin-bottom: 100px;
        opacity: 0.7;
        line-height: 24px;
      }

      .btn {
        width: 100%;
        height: 84px;
        margin-top: 36px;
        border: none;
        border-radius: 16px;

        &.btn01 {
          background: linear-gradient(180deg, #ffd64a 0%, #d2a408 100%);
        }

        &.btn02 {
          color: #fff;
          background: #393a3b;
        }
      }
    }
  }
}
</style>